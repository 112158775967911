import React from 'react';
import styles from './CardImageLink.module.scss';
import classNames from 'classnames';
import { ucFirst } from '../../utils/caseconverters';
import Button from '../Button';
import Icon from '../Icon';

const CardImageLink = ({ background, title, image, text, link }) => {
    const classes = classNames(styles['CardImageLink'], {
        [styles[`CardImageLink--` + ucFirst(background)]]: background,
    });

    return (
        <div className={classes}>
            {link.href && (
                <a className={styles['CardImageLink__Link']} href={link.href}>
                    <span className="sr-only">{title}</span>
                </a>
            )}
            <div className={styles['CardImageLink__Container']}>
                {image && image.renditions && (
                    <div className={styles['CardImageLink__Picture']}>
                        <img
                            width={185}
                            height={185}
                            sizes="343px"
                            srcSet={`${image.renditions.small.src} 600w,
                             ${image.renditions.medium.src} 1200w`}
                            src={image.renditions.small.src}
                            alt={image.alt}
                            style={{
                                objectPosition:
                                    image.focal.x + ' ' + image.focal.y,
                            }}
                        />
                    </div>
                )}
                <div className={styles['CardImageLink__Content']}>
                    <h3 className={styles['CardImageLink__Title']}>{title}</h3>
                    <div className={styles['CardImageLink__Text']}>{text}</div>
                    {link.text && (
                        <div className={styles['CardImageLink__EventLink']}>
                            <div
                                className={
                                    styles['CardImageLink__EventLinkText']
                                }>
                                {link.text}
                            </div>
                            {link.page ? (
                                <Icon type="arrow" color="pink" size="large" />
                            ) : (
                                <Icon
                                    type="external"
                                    color="pink"
                                    size="large"
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

CardImageLink.propTypes = {};

CardImageLink.defaultProps = {};

export default CardImageLink;
